import React from 'react'
import styled from 'styled-components'
import themeColor from 'utils/themeColor'
import Image from 'components/Image'
import FocusArea from 'components/FocusArea'

// ===================================================
// STYLES
// ===================================================

const Banner = styled('div')({
  position: 'relative',
  width: '100%',
  height: ({ aspectRatio }) => (aspectRatio === 'natural' ? 'initial' : 0),
  paddingBottom: ({ aspectRatio }) => aspectRatio,
  '& *': {
    color: ({ color, theme }) => themeColor(color, 'white', theme),
  },
  '@media (max-width:960px)': {
    height: '100%',
    paddingBottom: 'initial',
  },
})

const Columns = styled('div')({
  position: 'absolute',
  inset: 0,
  display: 'flex',
  '@media (max-width:960px)': {
    position: 'relative',
    flexDirection: 'column',
  },
})

const LeftColumn = styled('div')({
  position: 'relative',
  flex: 1.6,
  '@media (max-width:960px)': {
    height: ({ aspectRatio }) => (aspectRatio === 'natural' ? 'initial' : 0),
    paddingBottom: ({ aspectRatio }) => `calc(${aspectRatio} * 1.6)`,
  },
})

const PrimaryImage = styled(Image)({})

const RightColumn = styled('div')({
  position: 'relative',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
})

const SecondaryImageContainer = styled('div')({
  flex: 1.6,
  position: 'relative',
  '@media (max-width:960px)': {
    display: 'none',
  },
})

const SecondaryImage = styled(Image)({})

const SecondaryImageTextContainer = styled('div')({
  padding: 24,
  paddingRight: 'max((100vw - 1600px) / 2 , 24px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  position: 'absolute',
  inset: 0,
})

const ImageHeading = styled('span')({
  display: 'block',
  fontSize: '22px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginBottom: 8,
})

const ImageSubheading = styled('span')({
  display: 'block',
  fontSize: '22px',
})

const TextContainer = styled('div')({
  padding: 24,
  paddingRight: 'max((100vw - 1600px) / 2 , 24px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  flex: 0, // or 1 for widescreen SecondaryImage
  background: ({ background, theme }) =>
    themeColor(background, 'orange', theme),
})

const Tagline = styled('span')({
  fontSize: 17,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  marginBottom: 16,
  '@media (max-width: 600px)': {
    fontSize: 14,
  },
})

const Heading = styled('h1')({
  fontSize: 46,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  '@media (max-width: 600px)': {
    fontSize: 32,
  },
})

// ===================================================
// COMPONENTS
// ===================================================

const StoryblokHeaderBanner = ({
  data: {
    primaryImage,
    secondaryImage,
    imageHeading,
    // imageHeadingColor,
    imageSubheading,
    // imageSubheadingColor,
    tagline,
    // taglineColor,
    heading,
    // headingColor,
    background,
    color,
    aspectRatio = 'letterbox',
    focusArea,
  },
}) => (
  <Banner color={color} aspectRatio={aspectRatio}>
    <Columns>
      <LeftColumn aspectRatio={aspectRatio}>
        <PrimaryImage
          critical
          background
          src={primaryImage?.filename}
          alt={primaryImage?.alt}
        >
          {focusArea && <FocusArea {...focusArea} />}
        </PrimaryImage>
      </LeftColumn>
      <RightColumn>
        <SecondaryImageContainer>
          <SecondaryImage
            critical
            background
            src={secondaryImage?.filename}
            alt={secondaryImage?.alt}
          />
          <SecondaryImageTextContainer>
            <ImageHeading>{imageHeading}</ImageHeading>
            <ImageSubheading>{imageSubheading}</ImageSubheading>
          </SecondaryImageTextContainer>
        </SecondaryImageContainer>
        <TextContainer background={background}>
          <Tagline>{tagline}</Tagline>
          <Heading>{heading}</Heading>
        </TextContainer>
      </RightColumn>
    </Columns>
  </Banner>
)

export default StoryblokHeaderBanner
