import React from 'react'
import Component from './index.js'
import { extractGroup } from '../StoryblokGrid'

export default function EditableTransformer({ data, ...props }) {
  const focusArea = extractGroup(data.focusArea || data, [
    'top',
    'bottom',
    'left',
    'right',
  ])

  return <Component data={{ ...data, focusArea }} {...props} />
}
